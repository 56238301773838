import { Outlet, useOutletContext } from "react-router-dom";
import { useState } from "react";
import Header from "../organisms/header";
import Sidebar from "../organisms/sidebar";
import Toast from "../molecules/toast";
import { ProtectedLayoutOutletContext } from "./ProtectedLayout.tsx";

// OutletContext の型定義
export type ConsoleLayoutOutletContext = {
  me: ProtectedLayoutOutletContext;
  showToast: (message: string) => void;
};

function ConsoleLayout() {
  const me = useOutletContext<ProtectedLayoutOutletContext>();
  const [toastMessage, setToastMessage] = useState<string>("");
  const [isShowToast, setIsShowToast] = useState(false);

  const showToast = (message: string) => {
    setToastMessage(message);
    setIsShowToast(true);
  };

  return (
    <div>
      <Header />
      <div className="h-[52px]"></div>
      <Sidebar />
      <div className="ml-[220px] bg-#F3F4F6 min-h-screen font-hiragino">
        <Outlet
          context={{ me, showToast } satisfies ConsoleLayoutOutletContext}
        />
      </div>

      {isShowToast && (
        <Toast message={toastMessage} onClose={() => setIsShowToast(false)} />
      )}
    </div>
  );
}

export default ConsoleLayout;
