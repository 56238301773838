import { Outlet, useLocation } from "react-router-dom";
import { SignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import { useAuth } from "../../hooks/useAuth";
import Invitation from "../pages/InvitationPage";

function Redirect() {
  window.location.href = `${
    import.meta.env.VITE_P8N_L3_AUTH_URL
  }?redirect_url=${window.location.href}`;
  return null;
}

export type ProtectedLayoutOutletContext = {
  id: string;
  name: string;
  email: string;
  serviceID: string;
};

const ProtectedLayout = () => {
  const auth = useAuth();
  const location = useLocation();

  if (auth.loading) {
    return <></>;
  }

  return (
    <>
      <SignedIn>
        {auth.me && !location.pathname.startsWith("/invitation/") && (
          <Outlet context={auth.me satisfies ProtectedLayoutOutletContext} />
        )}
        {((!auth.me && location.pathname.startsWith("/invitation/")) ||
          (!auth.me && !location.pathname.startsWith("/invitation/")) ||
          (auth.me && location.pathname.startsWith("/invitation/"))) && (
          <Invitation />
        )}
      </SignedIn>
      <SignedOut>
        {import.meta.env.MODE === "local-dev" ? (
          <SignIn path="/" />
        ) : (
          <Redirect />
        )}
      </SignedOut>
    </>
  );
};

export default ProtectedLayout;
